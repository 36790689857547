import React, {useEffect, useState} from 'react'
import {Form, Input, Space} from "antd";
import {CopyOutlined, FileAddOutlined, SaveOutlined} from "@ant-design/icons";
import apiRequest from "../general/apiRequest";

//import {generate} from "generate-password-browser";

const apiUrlCredentialShow      = '/credential/show'
const apiUrlCredentialGenerate  = '/credential/generate'
const apiUrlCredentialSave      = '/credential/save'

export const Account = (props) => {
  const [error, setError] = useState("")
  const [userNameError, setUserNameError] = useState("")
  const [pass, setPass]  = useState("")
  const [loginName, setLoginName] = useState("")
  useEffect(() => {
    loadCredential()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.personId])
  const loadCredential = () => {
    if (props.personId) {
      apiRequest.get(`${apiUrlCredentialShow}/${props.personId}`)
        .then((rd) => {
          if (rd && rd.state && rd.data) {
            setLoginName(rd.data.loginName)
            setPass(null)
            setError("")
            setUserNameError("")
          }
        })
        .catch(error => console.log("loadCredential in Account: " + error))
    }
  }
  // const changePassword = (value) => setPass(value)
  const handleSetLoginName = (value) => {
    if (userNameError !== "") setUserNameError("")
    if (error !== "") setError("")
    setLoginName(value)
  }
  const handleSetPassword = (value) => {
    if (error !== "") setError("")
    setPass(value)
  }
  const handleSave = () => {
    apiRequest.post(apiUrlCredentialSave, JSON.stringify({personId: props.personId, loginName: loginName, password: pass}))
      .then((rd) => {
        if (rd && rd.data) {
          switch (rd.data) {
            case "SaveSuccessfully":
//              setPass(null)
              break
            case "PasswordUnsecure":
              setError("Passwort Unsicher (nicht gesetzt)")
//              setPass(null)
              break
            case "UsernameAlreadyExist":
              setUserNameError("Benutzer gibt es schon (nicht gesetzt)")
//              setPass(null)
              break
            case "NoPasswordAvailable":
              setError("Passwort nicht angegeben (nicht gesetzt)")
//              setPass(null)
              break
            default:
              setError("Allgemeiner Fehler beim setzten des Passworts")
//              setPass(null)
          }
          setPass(null)
        }
      })
      .catch(error => console.log("handelSave in Account: " + error))
  }
  const generatePassword = () => {
    apiRequest.get(apiUrlCredentialGenerate)
      .then((rd) => {
        if (rd && rd.state && rd.data) {
          setPass(rd.data.password)
        }
      })
      .catch(error => console.log("generatePassword in Account: " + error))
  }
  return <Form
    name="account-form"
    style={{padding: "12px 0"}}
    layout={"vertical"}
    autoComplete={"new-password"}
  >
    <Form.Item
      label="Anmeldename"
      style={{marginBottom: 0,}}
      validateStatus={userNameError !== ""?"error":null}
      help={userNameError !== ""?userNameError:null}
    >
      <Input
        autoComplete={"new-password"}
        value={loginName}
        onChange={(e) => {handleSetLoginName(e.target.value)}}
      />
    </Form.Item>
    <Form.Item
      label="Passwort"
      style={{marginBottom: 0,}}
      validateStatus={error !== ""?"error":null}
      help={error !== ""?error:null}
    >
      <Input.Password
        autoComplete={"new-password"}
        id="new-password"
        placeholder={"•".repeat(14)}
        value={pass}
        visibilityToggle
        onChange={(e) => {handleSetPassword(e.target.value)}}
        // onChange={(e) => {changePassword(e.target.value)}}
        addonAfter={<Space>
          <FileAddOutlined onClick={() => generatePassword()} />
          <CopyOutlined onClick={() => {navigator.clipboard.writeText(pass)}} />
          <SaveOutlined onClick={handleSave} />
        </Space>}
      />
    </Form.Item>
  </Form>
}
