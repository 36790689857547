export const parentTypeId = (name) => {
  switch (name) {
    case "project":     return "63bbfd74-ac46-4f52-9599-71e41ffcd7cc"
    case "breakdown":   return "e5ef1268-ea3b-4e04-ad9c-7f7f91aa6282"
    case "room":        return "fc1a5bd0-9b05-404d-95ff-a40c923b924c"
    case "workpiece":   return "071779d8-89c8-423a-b242-5fc2763d4c19"
    default:            return ""
  }
}

const ligniteId           = "f4001b52-3341-402a-b761-e36e32f381d0"
const lindlarId           = "e0668a92-fc1a-4a26-8238-352b040bf449"
const mainzId             = "b4c3b68c-8e3b-4ad9-8a13-97bcb3a43375"
const nauheimId           = "43671158-061b-46e0-8621-5c9de27bd32c"
const voelklingenId       = "535b83ed-80bc-46d4-add0-171ed10d34e4"
const augsburgId          = "7072f514-94d7-42a0-9d15-c91f24baaa35"
const brbGoetzId          = "8f1acb7d-5000-45e8-9ce9-087ef8f1e276"
const demoRaveId          = "92bb8df1-a8b0-4952-9ba6-dc3a6eb14aa6"
const jenischId           = "1d8450bc-78a7-4286-8872-167c7f0999ff"
const rabaliattiId        = "dff09f60-1429-4be1-b909-a4251b37ab0d"

/*export const isTenant = (tenantId) =>  {
  switch (tenantId) {
    case ligniteId:
    case lindlarId:
    case mainzId:
    case nauheimId:
    case voelklingenId:
    case augsburgId:
    case brbGoetzId:
      return true
    default: return false
  }
}*/

const brbGoetzAdminUserId     = "d3230728-ff1c-4b28-b3fa-83aa6aef234b"
const voelklingenAdminUserId  = "23e97a70-10fa-4172-96de-7cf1a6a624fd"
const ligniteAdminUserId      = "78f2a95a-b542-46b1-98a2-6fbccfdf392c"
const augsburgAdminUserId     = "083efc6b-c692-45e2-b43f-9e5fd604142d"
// old bug ?? const nauheimAdminUserId      = "3df8b91d-7bb0-4213-a5b0-b802a2b63d2f"
const nauheimAdminUserId      = "15cfc879-3161-483f-87b8-f61d005f1e69"
const lindlarAdminUserId      = "c3bfd226-5869-4307-9410-7bc5d0251e18"
// ??? const mainzAdminUserId        = "15cfc879-3161-483f-87b8-f61d005f1e69"
const mainzAdminUserId        = "3804a6eb-05cd-4fb4-9cbc-c11ce89da34f"
const demoRaveAdminUserId     = "176434be-6a63-426b-9113-36f915fcefc8"
const jenischAdminUserId      = "5a8dcbd8-2043-446f-afb8-3c79415a407a"
const rabaliattiAdminUserId   = "e52a8bd3-7b0c-4b75-9e97-7eb6fe550cf8"

export const isAdminUser = (userId) => {
  switch (userId) {
    case brbGoetzAdminUserId:
    case voelklingenAdminUserId:
    case mainzAdminUserId:
    case augsburgAdminUserId:
    case lindlarAdminUserId:
    case nauheimAdminUserId:
    case ligniteAdminUserId:
    case demoRaveAdminUserId:
    case jenischAdminUserId:
    case rabaliattiAdminUserId:
      return true
    default: return false
  }
}

export const isUserIdLigniteAdmin = (userId) => {
  return userId === ligniteAdminUserId
}
export const isTenantIdLigniteTenant = (tenantId) => {
  return tenantId === ligniteId
}
export const isLigniteTenant = (tenantId) => {
    switch (tenantId) {
      case ligniteId:
        return true
      default: return false
    }
}
export const isSharedExt = (tenantId) => {
  if (process.env.NODE_ENV !== 'production') return true
  if (window.location.host === "test.csesca.eu" || window.location.host === "demo.digivelop.de") return true
  switch (tenantId) {
  // switch true {
    case (demoRaveId === tenantId):
      return true
    default: return false
  }
}
export const isTempExt = (tenantId) => {
  if (process.env.NODE_ENV !== 'production') return true
  switch (tenantId) {
    case ligniteId:
    case nauheimId:
    case demoRaveId:
    case jenischId:
      return true
    default: return false
  }
}
export const isMrpExt = (tenantId) => {
    switch (tenantId) {
    case ligniteId:
    case mainzId:
    case nauheimId:
    case demoRaveId:
    case jenischId:
      return true
    default: return false
  }
}
export const isImpExt = (tenantId) => {
    switch (tenantId) {
    case mainzId:
    case demoRaveId:
      return true
    default: return false
  }
}
export const isExcelExt = (tenantId) => {
    switch (tenantId) {
      case mainzId:
      case lindlarId:
      case demoRaveId:
        return true
      default: return false
    }
}
export const tN = (tenantId, itemName) => {
  switch (tenantId) {
    case ligniteId:
      switch (itemName) {
        case "Struktur":
          return "Standort"
        case "Strukturen":
          return "Standorte"
        case "neue Struktur":
          return "neuer Standort"
        case "neue Struktur aus Template...":
          return "neuer Standort aus Template..."
        case "Strukturdaten":
          return "Standortdaten"
        case "Raum":
          return "Objekt"
        case "Räume":
          return "Objekte"
        case "neuer Raum":
          return "neues Objekt"
        case "neuer Raum aus Template...":
          return "neues Objekt aus Template..."
        case "Raumdaten":
          return "Objektdaten"
        case "Struktur/Raum":
          return "Standort / Objekt"
        case "Objekt":
          return "Ausstattungselement"
        case "Objektname":
          return "Ausstattungselement"
        case "Objekte":
          return "Ausstattungselemente"
        case "Kennzeichnungsnr.":
          return "Ausstattungsnummer"
//        case "Objekt": return "Ausstattung"
//        case "Objekte": return "Ausstattungen"
        case "neues Objekt":
          return "neues Ausstattungselement"
        case "neues Objekt aus Template...":
          return "neues Ausstattungselement aus Template..."
        case "Objektdaten":
          return "Ausstattungselementdaten"
        default:
          return itemName
      }
    default:
      return itemName
  }
}
export const canShowAddStandardButton = (isTemplate, tenantId) => {
  if (isLigniteTenant(tenantId)) {
    return isTemplate
  } else return true
}
export const canShowAddTemplateButton = (isTemplate, tenantId) => {
  if (isLigniteTenant(tenantId)) {
    return !isTemplate
  } else return isTempExt(tenantId)
}
export const canShowAddStandardButtonProject = (isTemplate, tenantId, userId) => {
  return !isLigniteTenant(tenantId) || isAdminUser(userId)
}
export const canShowAddTemplateButtonProject = (isTemplate, tenantId, userId) => {
  if (isLigniteTenant(tenantId))
    return isAdminUser(userId)
  else
    return isTempExt(tenantId) || isAdminUser(userId)
}
