import React, {useEffect, useState} from 'react'
import {Button, Popconfirm, Space} from "antd";
import {sysRight} from "./cmnRights";
import {DeleteOutlined, EditOutlined, FolderAddOutlined, ReadOutlined} from "@ant-design/icons";
import apiRequest from "../general/apiRequest";

export const ButtonRights = (
  {
    handleList,
    handleCreate,
    handleShow,
    handleDelete,
    buttonText = "",
    entityId = "",
    entityName = "",
    icon,
//    rights = [],
    rightTypes = [],
    titlePrefix = "",
    }) => {
  const [rights, setRights] = useState([])
  useEffect(() => {
    const loadRights = async () => {
      if (checkEntity()) {
        let id = ""
        if (entityId !== "") id += "/" + entityId
        const urn = "/" + entityName + "/rights" + id
        const rd = await apiRequest.get(urn)
        if (rd && rd.state && rd.rights) setRights(rd.data)
      }
    }
    loadRights()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [/*entityId, entityName*/])
  const checkEntity = () => {
    if (entityName) {
      switch (entityName) {
        case "project":
          return (entityId || (rightTypes.indexOf(sysRight.CREATE) >= 0))
        case "breakdown":
        case "room":
        case "workpiece":
          return (entityId)
        default:
          return true
      }
    } else return false
  }
  const shape = "round"
  return (
    <Space>
      {(rightTypes.indexOf(sysRight.LIST) !== -1)?
        <Button
          title={(buttonText === "")? (titlePrefix + " List").trimStart():null}
          type={"link"}
          disabled={rights.indexOf(sysRight.CREATE) < 0}
          shape={shape}
          icon={(buttonText === "")? (icon)? icon :<ReadOutlined style={{ fontSize: '1.5em'}} />: null}
          onClick={handleList}
        >{(buttonText !== "")? buttonText:""}</Button>
        :null}
      {(rightTypes.indexOf(sysRight.CREATE) !== -1)?
        <Button
          title={(buttonText === "")? (titlePrefix + " Anlagen").trimStart():null}
          type={"link"}
          disabled={rights.indexOf(sysRight.CREATE) < 0}
          shape={shape}
          icon={(buttonText === "")? (icon)? icon :<FolderAddOutlined style={{ fontSize: '1.5em'}} />: null}
          onClick={handleCreate}
        >{(buttonText !== "")? buttonText:""}</Button>
        :null}
      {(rightTypes.indexOf(sysRight.READ) !== -1)?
        <Button
          title={(titlePrefix + " Bearbeiten").trimStart()}
          type={"link"}
          disabled={rights.indexOf(sysRight.READ) < 0}
          shape={shape}
          icon={<EditOutlined style={{ fontSize: '1.5em'}}/>}
          onClick={handleShow}
        />
        :null}
      {(rightTypes.indexOf(sysRight.DELETE) !== -1)?
        <Popconfirm
          placement="topRight"
          title={"Möchten Sie den Datensatz löschen"}
          onConfirm={handleDelete}
          okText="Ja"
          cancelText="Nein"
          cancelButtonProps={{danger: false, type: "primary"}}
          okButtonProps={{danger: true}}
        >
          <Button
            title={(titlePrefix + " Löschen").trimStart()}
            type={"link"}
            danger={true}
            disabled={rights.indexOf(sysRight.DELETE) < 0}
            shape={shape}
            icon={(icon)? icon :<DeleteOutlined style={{ fontSize: '1.5em'}}/>}
          />
        </Popconfirm>
        :null}
    </Space>
  )
}
