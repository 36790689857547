import React, {useEffect, useState} from 'react';
import {Switch} from "antd";
import apiRequest from "../../general/apiRequest";

const apiUrlDataSchemaTypeList         = "/dataSchemaType/list"

export const SwitchSchema = ({dataSchemaId = "", checked, disabled, ...props} )=> {
  const [dataSchema, setDataSchema] = useState(null)
  useEffect(() => {
    const loadSchema = async () => {
      if (dataSchemaId) {
        const rd = await apiRequest.get(apiUrlDataSchemaTypeList)
        if (rd && rd.state && rd.data) setDataSchema(rd.data)
      }
    }
    loadSchema()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSchemaId])
  const checkedInternal =  (dataSchema)?(dataSchema?.hasSchemaDefault < 0)?checked:!!dataSchema?.hasSchemaDefault:false
  const disabledInternal = (disabled || !!(dataSchema && dataSchema?.hasSchemaDefault > -1))
  return (
    <Switch
      {...props}
      checked={checkedInternal}
      disabled={disabledInternal}
    />
  )
}