import React, {useEffect, useState}  from 'react'
import {Button, Card, Checkbox, Col, Input, InputNumber, List, Popconfirm, Row, Tooltip} from "antd";
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import apiRequest from "../general/apiRequest";
import {SelectNodeMeta} from "./select/SelectNodeMeta";
import {SelectAggregationType} from "./select/SelectAggregationType";

const apiUrlEdgeMetaShow          = "/edgeMeta/show"
// const apiUrlEdgeMetaSave          = "/edgeMeta"
const apiUrlEdgeMetaGetBySourceId = "/edgeMeta"

const newEdgeMeta = {
  id: null,
  active: true,
  position: -1,
  name: "...loding",
  sourceNodeMetaId: null,
  targetNodeMetaId: null,
  physicalName: "",
  sourceCardinality: 0,
  targetCardinality: 0,
  optional: true,
  aggregationTypeId: null,
  description: "",
}

export const EdgeMetaEditList = ({bindTo, nodeMetaId, ...props}) => {
  const [edgeMetaIdList, setEdgeMetaIdList] = useState([])
  const bindNoteMetaName = `${bindTo}NodeMetaId`
  useEffect (() => {
    loadEdgeMetaListId()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.nodeMetaId])
  const loadEdgeMetaListId = () => {
    if (nodeMetaId && (bindTo === "source" || bindTo === "target")) {
      apiRequest.get(`${apiUrlEdgeMetaGetBySourceId}/${bindTo}/${nodeMetaId}`)
        .then(rd => {
          if (rd && rd.state && rd.data) setEdgeMetaIdList(rd.data)
        })
        .catch(error => console.log("loadEdgeMetaListId in EdgeMetaEditList: " + error))
    }
  }
  const addEdgeMeta = () => {
    apiRequest.post("/edgeMeta", JSON.stringify({...newEdgeMeta, [bindNoteMetaName]: nodeMetaId, name: "New Edge"}))
      .then(rd => {
        if (rd && rd.state && rd.data) loadEdgeMetaListId()
      })
      .catch(error => console.log("addEdgeMeta in EdgeMetaEditList: " + error))
  }
  const deleteEdgeMeta = (id) => {
    apiRequest.delete("/edgeMeta/" + id)
      .then(_ =>loadEdgeMetaListId())
      .catch(error => console.log("deleteEdgeMeta in EdgeMetaEditList: " + error))
  }
  return (<Card title={"EdgeMeta"}>
      <List>
        <List.Item>
          <Row gutter={2} style={{paddingLeft: "4px", width: "100%"}}>
            <Col span={4}><Tooltip title={<>Name<br/>(Description)</>}>Name</Tooltip></Col>
            <Col span={4}><Tooltip title="Physikalischer Name">Phys.-Name</Tooltip></Col>
            <Col span={3}>
            {(bindTo!=="source")
              ?<Tooltip title={<>Quell-Entität<br/><span style={{fontSize: ".8em"}}>(Source node meta)</span></>}>Quelle</Tooltip>
              :<Tooltip title={<>Ziel-Entität<br/><span style={{fontSize: ".8em"}}>(Target node meta)</span></>}>Ziel</Tooltip>}
            </Col>
            <Col span={1}><Tooltip title={<>Quell-Kardinalität<br/>(Source cardinality)</>}>QK</Tooltip></Col>
            <Col span={1}><Tooltip title={<>Ziel-Kardinalität<br/>(Target cardinality)</>}>ZK</Tooltip></Col>
            <Col span={1}><Tooltip title="Optional">Opt.</Tooltip></Col>
            <Col span={3}><Tooltip title="Aggregationstyp">Aggregationstyp</Tooltip></Col>
            <Col span={0}>&nbsp;</Col>
            <Col offset={6} span={1}>
              <Button
                type={"link"}
                shape={"round"}
                onClick={addEdgeMeta}
                icon={<PlusOutlined />} />
            </Col>
          </Row>
        </List.Item>
        {edgeMetaIdList.map((item) =>
          <EdgeMetaEdit
            edgeMetaId={item}
            deleteEdgeMeta={deleteEdgeMeta}
          />
        )}
      </List>
    </Card>
  )
}

const EdgeMetaEdit = ({bindTo, edgeMetaId, deleteEdgeMeta, ...props}) => {
  const [edgeMeta, setEdgeMeta] = useState({})
  const [changed, setChanged] = useState(false)
  const [textFocus, setTextFocus] = useState({ minRows: 1, maxRows: 1 })
  useEffect(() => {
    loadEdgeMeta()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const loadEdgeMeta = async () => {
    if (edgeMetaId) {
      const rd = await apiRequest.get(`${apiUrlEdgeMetaShow}/${edgeMetaId}`)
      if (rd && rd.state && rd.data) setEdgeMeta(rd.data)
    }
  }
  const handleChange = (evt) => {
    const fieldName = evt.target.name
    const fieldValue = evt.target.type === "checkbox" ? evt.target.checked : evt.target.value
    setEdgeMeta({...edgeMeta, [fieldName]: fieldValue})
    setChanged(true)
  }
  const handleChangeNumber = (name, value) => {
    setEdgeMeta({...edgeMeta, [name]: value})
    setChanged(true)
  }
  const handleChangeSpecialChar = (evt) => {
//    const value = evt.target.value.match(/(A-Z)|([a-zA-Z0-9_]*)/)
    const value = evt.target.value.match(/[a-zA-Z0-9_]*/)
    if (value) {
//      console.log("Value: "  + value)
      setEdgeMeta({...edgeMeta, [evt.target.name]: value.toString()})
      setChanged(true)
    }
  }
  const handleSelectChange = (name, value) => {
    setEdgeMeta({...edgeMeta, [name]: value})
    setChanged(true)
  }
  const handleBlur = async () => {
    if (changed) {
      await apiRequest.put("/edgeMeta", JSON.stringify(edgeMeta))
    }
    setTextFocus({ minRows: 1, maxRows: 1 })
  }
  return (
    <List.Item
      key={"PMLI_" + edgeMetaId}
      style={{padding: "2px 0", display:"flow-root"}}
    ><div>
      <Row gutter={2} style={{width: "100%"}} align={"middle"}>
        <Col span={4}>
          <Input
            name="name"
            value={edgeMeta.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Col>
        <Col span={4}>
          <Input
            name="physicalName"
            value={edgeMeta.physicalName}
            onChange={handleChangeSpecialChar}
            onBlur={handleBlur}
          />
        </Col>
        <Col span={3}>
          {(bindTo !== "source")
            ? <SelectNodeMeta
              name="sourceNodeMetaId"
              value={edgeMeta.sourceNodeMetaId}
              showEmpty={true}
              disableEmpty={true}
              textEmpty="- leer -"
              onChange={(value) => {
                handleSelectChange("sourceNodeMetaId", value)
              }}
              onBlur={handleBlur}
              readOnly={bindTo === "source"}
            />
            : <SelectNodeMeta
              name="targetNodeMetaId"
              value={edgeMeta.targetNodeMetaId}
              showEmpty={true}
              disableEmpty={true}
              textEmpty="- leer -"
              onChange={(value) => {
                handleSelectChange("targetNodeMetaId", value)
              }}
              onBlur={handleBlur}
              readOnly={bindTo === "target"}
            />
          }
        </Col>
        <Col span={1}>
          <InputNumber
            name="sourceCardinality"
            value={edgeMeta.sourceCardinality}
            onChange={(value => {handleChangeNumber("sourceCardinality", value)})}
            onBlur={handleBlur}
            controls={false}
            max={8}
            maxLength={2}
            precision={0}
            style={{width: "100%", textAlign: "right"}}
          />
        </Col>
        <Col span={1}>
          <InputNumber
            name="targetCardinality"
            value={edgeMeta.targetCardinality}
            onChange={(value => {handleChangeNumber("targetCardinality", value)})}
            onBlur={handleBlur}
            controls={false}
            max={8}
            maxLength={2}
            precision={0}
            style={{width: "100%", textAlign: "right"}}
          />
        </Col>
        <Col span={1} style={{textAlign: "center"}}>
          <Checkbox
            name="optional"
            checked={edgeMeta.optional}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Col>
        <Col span={3}>
          <SelectAggregationType
            name="aggregationTypeId"
            value={edgeMeta.aggregationTypeId}
            showEmpty={true}
            disableEmpty={true}
            textEmpty="- leer -"
            onChange={(value) => {handleSelectChange("aggregationTypeId", value)}}
            onBlur={handleBlur}
          />
        </Col>
      </Row>
    </div>
      <div>
        <Row gutter={2} style={{width: "100%"}}>
          <Col span={8}>
            <Input.TextArea
              name="description"
              value={edgeMeta.description}
              autoSize={textFocus}
              maxLength={2000}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={() => {setTextFocus({ minRows: 2, maxRows: 6 })}}
            />
          </Col>
          <Col span={15}>&nbsp;</Col>
          <Col
            offset={0}
            span={1} style={{textAlign: "right"}}>
            <Popconfirm
              placement="topRight"
              title={"Möchten Sie den Datensatz löschen"}
              onConfirm={() => {deleteEdgeMeta(edgeMetaId)}}
              okText="Ja"
              cancelText="Nein"
              cancelButtonProps={{danger: false, type: "primary"}}
              okButtonProps={{danger: true, ghost: true}}
            >
              <Button
                type={"link"}
                danger={true}
                shape={"round"}
                style={{padding: "0 10px"}}
                icon={<DeleteOutlined style={{fontSize: "2em"}}
                />}
              />
            </Popconfirm>
          </Col>
        </Row>
    </div></List.Item>
  )
}