import React, {useEffect, useState} from 'react';
import {Select} from "antd";
import apiRequest from "../../general/apiRequest";
import {CloseCircleFilled} from "@ant-design/icons";

const apiUrlRoomProjectSelect = "/room/byProject"

export const SelectProjectRooms = ({allowClear = false, onBlur, onChange, projectId, readOnly = false, workId, ...props}) => {
  const [rooms, setRooms] = useState([])
//  const {readOnly, workId, projectId, onChange, ...rest} = props
  useEffect(() => { loadRoomProjects(projectId)}, [projectId])
  const loadRoomProjects = (projectId) => {
    if (projectId) {
      apiRequest.get(`${apiUrlRoomProjectSelect}/${projectId}`)
        .then(rd => {
          if (rd && rd.state && rd.data)  setRooms(rd.data)
        })
        .catch(error => console.log("Error in SelectProjectRooms: " + error))
    }
  }
  const handleChange = (value, option) => {if (!readOnly && onChange) onChange(value)}
  return (
    <Select
      key={"CT" + workId}
      style={{ width: '100%' }}
      fieldNames={{label: "name", value: "id",}}
//      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      {...props}
      // onChange={!readOnly && onChange}
      onChange={handleChange}
      onBlur={!readOnly && onBlur}
      allowClear={(!readOnly && allowClear)?{clearIcon:<CloseCircleFilled twoToneColor="#eb2f96"/>}:false}
      open={readOnly ? false : undefined}
      options={rooms}
    />
  )
}
