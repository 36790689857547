import React, {useState} from 'react';
import {Checkbox, Col, Form, message, Modal, Row} from "antd";

export const DeepCopySelect =
  ({
    open = false,
    deepCopyProps = {open: false, sourceId: null,},
    onCancel = () => {message.error(<p>Cancle Funktion nicht angelegt</p>,5)},
    onResult = (value) => {(message.error(<p>Ok Funktion nicht angelegt<br />DeepCoopy: {value}</p>,5))},
    ...props
  }) => {
  const [deepCopy, setDeepCopy] = useState(false)
    return (deepCopyProps.open && !!deepCopyProps.sourceId &&
    <Modal
      open={deepCopyProps.open && !!deepCopyProps.sourceId}
      centered={true}
      maskClosable={true}
      destroyOnClose={true}
      cancelText="Abbrechen"
      okText="Auswählen"
      onOk={() => onResult({deepCopy, sourceId: deepCopyProps.sourceId ,})}
      onCancel={onCancel}
    >
      <Row>
        <Col span={24}>
          <h5>Kopieren</h5>
          <Form  name="deep-copy-select-form"
            colon=":"
            layout="horizontal"
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 14 }}
          >
            <Form.Item label="Tiefe Kopie" name="deepCopy" style={{marginBottom: 0}}>
              <Checkbox
                name="deepCopy"
                checked={deepCopy}
                onChange={(e => {setDeepCopy(e.target.checked)})}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  )
}