import React, {useEffect, useState} from 'react'
import {Row, Col, Input, Button, Space, Switch} from 'antd'
import {sysRight} from "../common/cmnRights"
import apiRequest from "../general/apiRequest"
import {DragOutlined, MinusOutlined, PlusOutlined, SelectOutlined} from "@ant-design/icons";
import {parentTypeId} from "../system/checkTenants";
import {SelectRaveAssignmentFunction} from "../common/SelectRaveAssignmentFunction";
import {covImgFunc} from "../rascal/rascalApiDef";
import {TitleImage} from "../images/imageMapHover";
import {TreeSelectMultiAssignParent} from "../common/TreeSelectMultiAssignParent";
import {useNavigate} from "react-router-dom";


const apiUrlRaveMultiAssignmentShow               = "/raveMultiAssignment/show"
const apiUrlRaveMultiAssignmentSave               = "/raveMultiAssignment"

const apiUrlRaveMultiAssignmentRefList            = "/raveMultiAssignmentRef/list"
const apiUrlRaveMultiAssignmentRefCreate          = "/raveMultiAssignmentRef"
const apiUrlRaveMultiAssignmentRefSave            = "/raveMultiAssignmentRef"
const apiUrlRaveMultiAssignmentRefShow            = "/raveMultiAssignmentRef/show"
const apiUrlRaveMultiAssignmentRefDelete          = "/raveMultiAssignmentRef"

const apiUrlRaveMultiAssignmentRefItem            = "/raveMultiAssignmentRef/item"


const defaultRaveMultiAssignment = {
  id: null,
  active: true,
  position: -1,
  parentId: null,
  functionId: null,
  name: "",
  description: "",
}
export const RaveMultiAssignmentPanel = ({editable = false, fromTemplate = false, ...props}) => {
  const [raveMultiAssignment, setRaveMultiAssignment] = useState({...defaultRaveMultiAssignment, parentId: props.itemId})
  const [raveMultiAssignmentRefIds, setRaveMultiAssignmentRefIds] = useState([])
  const [isChanged, setIsChanged] = useState(false)
  const [selectedItem, setSelectedItem] =useState(null)

  useEffect(() => {
    loadRaveMultiAssignment(props.itemId)}
// eslint-disable-next-line react-hooks/exhaustive-deps
    , [])
  const loadRaveMultiAssignment = async raveMultiAssignmentId => {
    if (raveMultiAssignmentId) {
      const rd = await apiRequest.get(`${apiUrlRaveMultiAssignmentShow}/${raveMultiAssignmentId}`)
      if (rd && rd.state && rd.data) {
        setRaveMultiAssignment(rd.data)
        loadRaveMultiAssignmentRefIds(rd.data.id)
      }
    }
  }
  const loadRaveMultiAssignmentRefIds = async (id) => {
    if (id) {
      const rd = await apiRequest.get(`${apiUrlRaveMultiAssignmentRefList}/${id}`)
      if (rd && rd.state && rd.data) {
        setRaveMultiAssignmentRefIds(rd.data)
      }
    }
  }
  const handleSave = async () => {
    if (isChanged && raveMultiAssignment.parentId) {
      await apiRequest.put(apiUrlRaveMultiAssignmentSave, JSON.stringify(raveMultiAssignment))
      setIsChanged(false)
    }
  }
  const handleChildBlur = () => { handleSave() }
  const handleChildChange = (evt) => {
    if (editable) {
      const name = evt.target.name
      const value = evt.target.type === "checkbox" ? evt.target.checked : evt.target.value
      setRaveMultiAssignment(prevState => {return {...prevState, [name]: value}})
      setIsChanged(true)
    }
  }
  const handleSelectChange = (name, value) => {
    setRaveMultiAssignment(prevState => {return {...prevState, [name]: value}})
    setIsChanged(true)
  }
  const handleCreateAssignmentRef = async () => {
    if (selectedItem) {
      const multiAssignmentRef = {
        parentId: props.itemId,
        assignmentId: selectedItem,
        description: "",
        showAsPanel: false,
      }
      const rd = await apiRequest.post(`${apiUrlRaveMultiAssignmentRefCreate}`, JSON.stringify(multiAssignmentRef))
      if (rd && rd.state && rd.data) {
        loadRaveMultiAssignmentRefIds(raveMultiAssignment.id)
      }
    }
    setSelectedItem(null)
  }
  const handleDeleteAssignmentRef = async (itemId) => {
    if (itemId) {
      const rd = await apiRequest.delete(`${apiUrlRaveMultiAssignmentRefDelete}/${itemId}`)
      if (rd && rd.state) {
        loadRaveMultiAssignmentRefIds(raveMultiAssignment.id)
      }
    }
  }
//  const {editable, fromTemplate} = props
  const update = (props.rightsParent.indexOf(sysRight.UPDATE) >= 0)
  return (
    <div className={"ant-card ant-card-bordered ant-card-small"}>
      <div className={"ant-card-head"}>
        <div className={"ant-card-head-wrapper"}>
          <div className={"ant-card-head-title"}>
            <Row gutter={[16,16]}>
              {(!fromTemplate && editable)
                ?<Col span={1}>{(!fromTemplate)?<DragOutlined style={{ cursor: 'grab', color: '#999' }} />:null}</Col>
                :null
              }
              <Col span={5}>
                <Input
                  name={"name"}
                  value={raveMultiAssignment.name}
                  onBlur={() => {handleChildBlur()}}
                  onChange={(evt) => {handleChildChange(evt)}}
                  readOnly={!editable || !update}
                  disabled={!update}
                />
              </Col>
              <Col span={6}>
                <SelectRaveAssignmentFunction
                  nodeId={parentTypeId(props.parentType)}
                  allowClear={true}
                  value={raveMultiAssignment.functionId}
                  onBlur={() => {handleChildBlur()}}
                  onChange={(tsData, tsLabel, tsExtra) => {handleSelectChange("functionId", tsData)}}
                  readOnly={!editable || !update}
                  disabled={!update}
                />
              </Col>
            </Row>
          </div>
          <div className={"ant-card-head-extra"}>
            {(editable)?props.extra:null}
          </div>
        </div>
      </div>
      <div className={"ant-card-body"}>
        <Row gutter={[16,16]}>
          <MultiAssignmentPrimary
            workId={props.workId}
            parentId={props.workParentId}
            parentType={props.parentType}
          />
          <Col
            span={24}
          >
            <Input.TextArea
              name={"description"}
              value={raveMultiAssignment.description}
              showCount={editable}
              autoSize={editable?{ minRows: 6, maxRows: 10 }:{ minRows: 4, maxRows: 124 }}
              style={{resize: "none"}}
              onBlur={() => {handleChildBlur()}}
              onChange={(evt) => {handleChildChange(evt)}}
              readOnly={!editable || !update}
              disabled={!update}
            />
          </Col>
          {raveMultiAssignmentRefIds.map(
            (itemId) => <MultiAssignmentSecondary
              workId={props.workId}
              parentId={props.workParentId}
              parentType={props.parentType}
              multiAssignmentId={itemId}
              update={update}
              editable={editable}
              deleteMultiAssignmentRef={(itemId) => {handleDeleteAssignmentRef(itemId)}}
            />)}
          <Col span={24} style={{textAlign: "right"}}>
            <Space
              style={{border: "1px solid grey", padding: "5px"}}
            >
              <span>Neue Zuordnung:</span>
              <TreeSelectMultiAssignParent
                value={selectedItem}
                onChange={(value, label, extra) => {setSelectedItem(value)}}
                projectId={props.projectId}
                workId={props.workId}
                multiAssignmentId={raveMultiAssignment.id}
                parentType={props.parentType}
                style={{minWidth: "280px"}}
                allowClear={true}
                placeholder={"Bitte auswählen"}
                fieldNames={{label: 'name', value: 'id', children: 'children'}}
                dropdownStyle={{maxWidth: "300px", maxHeight: 400, overflow: 'auto'}}
              />
              <Button
                icon={<PlusOutlined />}
                onClick={handleCreateAssignmentRef}
              />
            </Space>
          </Col>
        </Row>
      </div>
    </div>
  )
}

const defParent = {
  id: null,
  codeNumber: "",
  name: "",
}
const MultiAssignmentPrimary = ({...props}) => {
  const navigate = useNavigate()
  const [parent, setParent] = useState(defParent)
  useEffect(() => {loadParent(props.parentId)}
// eslint-disable-next-line react-hooks/exhaustive-deps
    , [])
  const loadParent = async (parentId) => {
    if (parentId) {
      switch (props.parentType) {
        case "breakdown":
        case "room":
        case "workpiece":
          const rd = await apiRequest.get(`${apiUrlRaveMultiAssignmentRefItem}/${parentId}`)
          if (rd && rd.state && rd.data) setParent(rd.data)
      }
    }
  }
  const gotoParent = () => {
    if (parent?.itemType !== "" && parent?.id !== "") {
      switch (parent.itemType) {
        case "breakdown":
        case "room":
          navigate(`/ras/${parent.itemType}/${parent?.id}`)
      }
    }
  }
  return <>
    <Col
      span={3}
    >
      Primäre Zuordung:
    </Col>
    <Col
      span={6}
    >
      <Input
        disabled
//        readOnly
        bordered={false}
        value={parent.codeNumber}
        />
    </Col>
    <Col
      span={8}
    >
      <Input
        disabled
//        readOnly
        bordered={false}
        value={parent.name}
        />
    </Col>
    <Col
      span={7}
      style={{textAlign: "right"}}
    >
      <Button
        icon={<SelectOutlined rotate={90} />}
        onClick={gotoParent}
      />
    </Col>
  </>
}

const defaultMultiAssignmentRef = {
  id: null,
  parentId: null,
  assignmentId: null,
  description: "",
  showAsPanel: false,
}
const defaultMultiAssignmentRefParent = {
  id: null,
  codeNumber: "",
  name: "",
}
const MultiAssignmentSecondary = ({parentId, multiAssignmentId, editable = false, update = false, ...props}) => {
  // const {parentId, multiAssignmentId, editable, update, ...rest} = props
  const navigate = useNavigate()
  const [multiAssignmentRef, setMultiAssignmentRef] = useState(defaultMultiAssignmentRef)
  const [itemParent, setItemParent] = useState(defaultMultiAssignmentRefParent)
  useEffect(() => {loadMultiAssignment(multiAssignmentId)}
// eslint-disable-next-line react-hooks/exhaustive-deps
    , [])
  const loadMultiAssignment = async (itemId) => {
    if (itemId) {
      const rd = await apiRequest.get(`${apiUrlRaveMultiAssignmentRefShow}/${itemId}`)
      if (rd && rd.state && rd.data) {
        setMultiAssignmentRef(rd.data)
        loadMultiAssignmentParent(rd.data.assignmentId)
      }
    }
  }
  const loadMultiAssignmentParent = async (itemId) => {
    if (itemId) {
      switch (props.parentType) {
        case "breakdown":
        case "room":
        case "workpiece":
          const rd = await apiRequest.get(`${apiUrlRaveMultiAssignmentRefItem}/${itemId}`)
          // const rd = await apiRequest.get(`/${props.parentType}/${itemId}`)
          if (rd && rd.state && rd.data) setItemParent(rd.data)
      }
    }
  }
  const imgContext = {
    refererId: itemParent.id,
    projectId: itemParent.projectId,
  }
  const handleChange = (evt) => {
    const name = evt.target.name
    const value = evt.target.type === "checkbox" ? evt.target.checked : evt.target.value
    setMultiAssignmentRef((prevState) => {return {...prevState, [name]: value}})
  }
  const handleChangeSwitch = (name, checked) => {
    setMultiAssignmentRef((prevState) => {return {...prevState, [name]: checked}})
  }
  const handleSave = async () => {
    const rd = await apiRequest.put(`${apiUrlRaveMultiAssignmentRefSave}`, JSON.stringify(multiAssignmentRef))
    if (rd && rd.state && rd.data) {

    }
  }
  const handleChildBlur = () => { handleSave() }
  const handleDelete = () => {
    if (props.deleteMultiAssignmentRef) {
      props.deleteMultiAssignmentRef(props.multiAssignmentId)
    }
  }
  const gotoParent = () => {
    if (itemParent?.itemType !== "" && itemParent?.id !== "") {
      switch (itemParent.itemType) {
        case "breakdown":
        case "room":
          navigate(`/ras/${itemParent.itemType}/${multiAssignmentRef.assignmentId}`)
          return
      }
    }
  }
  return <>
    <Col span={24}>
      <hr />
      <Row>
        <Col span={4}>
          <TitleImage
            key={"RMA_" + multiAssignmentId}
            imgFunction={covImgFunc}
            covImg={imgContext}
            rightsParent={[]}
          />
        </Col>
        <Col span={20}>
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Input
                value={itemParent.codeNumber}
                borderless
//                bordered={false}
                placeholder="Nr"
                disabled
              />
            </Col>
            <Col span={12}>
              <Input
                value={itemParent.name}
                borderless
//                bordered={false}
                placeholder="Name"
                disabled
              />
            </Col>
            <Col span={6} style={{textAlign: "right"}}>
              <Space>
                <Button
                  icon={<SelectOutlined rotate={90} />}
                  onClick={gotoParent}
                />
                <Button
                  icon={<MinusOutlined />}
                  onClick={handleDelete}
                />
                <Switch
                  onChange={(checked, event) => {handleChangeSwitch("showAsPanel", checked)}}
                  onBlur={() => {handleChildBlur()}}
                  checked={multiAssignmentRef?.showAsPanel}
                  disabled={!editable || !update}
                />
              </Space>
            </Col>
            <Col span={24}>
              <Input.TextArea
                name={"description"}
                value={multiAssignmentRef?.description}
                onChange={handleChange}
                onBlur={handleSave}
//                autoSize={false}
                style={{resize: "none"}}
                readOnly={!editable || !update}
                disabled={!update}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  </>
}
