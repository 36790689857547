/**
 * Created by LechTel.
 *
 * User: cklimesch
 *
 * Date: 04.07.2023
 *
 * Project: csescaclient
 *
 * panelRaveMultiAsignmentRef in package  (project: csescaclient ) © 04.07.2023
 *
 * To change this template use File | Settings | File and Code Templates.
 */
import React, {useEffect, useState} from 'react'
import {Button, Col, DatePicker, Input, Row, Space} from "antd";
import apiRequest from "../general/apiRequest";
import {CalendarOutlined, DeleteOutlined, DownOutlined, PlusOutlined} from "@ant-design/icons";
import {SelectCatalog} from "../common/cmnSelectCatalog";

import "./css/RaveRelocationEntry.css"
import {SelectProjectRooms} from "../rascal/workpiece/cmnSelectProjectRoom";

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import 'dayjs/locale/de'

//import localeDE from 'antd/es/date-picker/locale/de_DE';

dayjs.extend(utc)
dayjs.locale('de');

const apiUrlRaveRelocationEntryShow             = "/raveRelocationEntry/show"
const apiUrlRaveRelocationEntrySave             = "/raveRelocationEntry"
const apiUrlRaveRelocationEntryCreate           = "/raveRelocationEntry"
const apiUrlRaveRelocationEntryDelete           = "/raveRelocationEntry"

const apiUrlRaveRelocationEntryList             = "raveRelocationEntry/list"

const defaultRaveRelocationEntry = {
  id: null,
  parentId: null,
  locationId: null,
  locationFrom: null,
  description: "",
  stock: "",
  functionId: null,
  locationUntil: null,
  locationIsActive: false,
}

export const RaveRelocationEntryList = ({readOnly, editable, update, parentType, parentNodeTypeId, parentPanelId, projectId}) => {
  const [raveRelocationEntryListIds, setRaveRelocationEntryListIds] = useState([])
  const [dateChange, setDateChange] = useState(new Date())
  useEffect(() => {loadRelocationEntryList(parentPanelId)}, [parentPanelId])
  const loadRelocationEntryList = (id, isDateChange) => {
    if (id) {
      apiRequest.get(`${apiUrlRaveRelocationEntryList}/${id}`)
        .then(rd => {
          if (rd && rd.state && rd.data) {
            setRaveRelocationEntryListIds(prevState => rd.data)
            if (isDateChange) {
              setDateChange(new Date())
            }}})
        .catch(error => console.log("Error in RaveRelocationEntryList: " + error))
    }
  }
  const addRelocationEntry = async () => {
    const dt = dayjs.utc(new Date())
    const rd = await apiRequest.post(`${apiUrlRaveRelocationEntryCreate}`, JSON.stringify({
      ...defaultRaveRelocationEntry,
      parentId: parentPanelId,
      locationFrom: dt.startOf('d'),
    }))
    if (rd && rd.state && rd.data) {
      loadRelocationEntryList(parentPanelId)
    }
  }
  const deleteRelocationEntry = async (id) => {
    const rd = await apiRequest.delete(`${apiUrlRaveRelocationEntryDelete}/${id}`)
    if (rd && rd.state) {
      loadRelocationEntryList(parentPanelId)
    }
  }
  const handleDateChange = () => {
    loadRelocationEntryList(parentPanelId, true)
  }
  return (<>
      {(raveRelocationEntryListIds.length > 0)
      ?raveRelocationEntryListIds.map(
        (raveRelocationEntryId) => <RaveRelocationEntry
          key={"REI_" + raveRelocationEntryId}
          projectId={projectId}
          raveRelocationEntryId={raveRelocationEntryId}
          raveRelocationDelete={deleteRelocationEntry}
          parentType={parentType}
          readOnly={readOnly}
          update={update}
          editable={editable}
          handleDateChange={handleDateChange}
          dateChange={dateChange}
        />)
      :<Row><Col span={24}>&nbsp;</Col></Row>}
      {(editable && !readOnly)?<Row>
        <Col span={24} style={{textAlign: "right"}}>
          <Space>
            Neue Umlagerung
            <Button
              icon={<PlusOutlined />}
              onClick={addRelocationEntry}
              // onClick={() => {window.alert("Neue Umlagerung")}}
              disabled={!editable || readOnly}
            />
          </Space>
        </Col>
      </Row>
      :null}
  </>
  )
}

const RaveRelocationEntry = ({readOnly, editable, update, raveRelocationEntryId, projectId, parentType, raveRelocationDelete, handleDateChange, dateChange, ...props}) => {
  const [raveRelocationEntry, setRaveRelocationEntry] = useState(defaultRaveRelocationEntry)
  const [isChanged, setIsChanged] = useState(false)
  const [isDateChanged, setIsDateChanged] = useState(false)
  useEffect(() => {loadRelocationEntry(raveRelocationEntryId)}, [raveRelocationEntryId, dateChange])
  const loadRelocationEntry = async (id) => {
    if (id) {
      const rd = await apiRequest.get(`${apiUrlRaveRelocationEntryShow}/${id}`)
      if (rd && rd.state && rd.data) setRaveRelocationEntry(rd.data)
    }
  }
  const handleSave = () => {
    if (isChanged && raveRelocationEntry.parentId) {
      apiRequest.put(apiUrlRaveRelocationEntrySave, JSON.stringify(raveRelocationEntry))
        .then(rd => {
          setIsChanged(prevState => false)
          setIsDateChanged(prevState => false)
          if (isDateChanged) handleDateChange()
        })
        .catch(error => console.log("Error in RaveRelocationEntry: " + error))
    }
  }
  const handleChildBlur = () => { handleSave() }
  const handleChildChange = (evt) => {
    if (editable) {
      const name = evt.target.name
      const value = evt.target.type === "checkbox" ? evt.target.checked : evt.target.value
      setRaveRelocationEntry(prevState => {return {...prevState, [name]: value}})
      setIsChanged(true)
    }
  }
  const handleDatePickerChange = (name, date, dateString) => {
    // this.setState({capture:{...this.state.capture, constrYearEndText: date}})
    setRaveRelocationEntry(prevState => {return {...prevState, [name]: date}})
    setIsChanged(true)
    setIsDateChanged(true)
  }
  const handleSelectChange = (name, value) => {
    setRaveRelocationEntry(prevState => {return {...prevState, [name]: value}})
    setIsChanged(true)
  }
  const locationFromDate = (raveRelocationEntry && raveRelocationEntry.locationFrom) ? dayjs(new Date(raveRelocationEntry.locationFrom)): null
  const locationUntilDate = (raveRelocationEntry && raveRelocationEntry.locationUntil) ? dayjs(new Date(raveRelocationEntry.locationUntil)): null
  return <Row
    key={"RE_" + raveRelocationEntry.id}
    gutter={[2,2]}
    className={"rave-entry-container"}
//    className={(raveRelocationEntry?.locationIsActive)?"rave-entry-active":null}
  >
    <div
      className={(raveRelocationEntry?.locationIsActive)?"rave-entry-item rave-entry-active":"rave-entry-item"}
//      style={{width: "100%", backgroundColor: (raveRelocationEntry?.locationIsActive)?"lightgreen":"inherit", display: "inherit"}}
    >
    <Col span={2}>
      <DatePicker
        value={locationFromDate}
        name="locationFrom"
        placeholder={"Datum der Fertigstellung"}
        onChange={!readOnly && ((date, dateString) => {handleDatePickerChange("locationFrom", date, dateString)})}
        format={"DD.MM.YYYY"}
        onBlur={!readOnly && handleChildBlur}
        open={readOnly ? false : undefined}
        readOnly={readOnly}
        disabled={!update}
        allowClear={!readOnly && update}
        suffixIcon={(!readOnly && update)?<CalendarOutlined />:null}
      />
    </Col>
    <Col span={2}>
      <DatePicker
        value={locationUntilDate}
        name="locationUntil"
        placeholder={"Datum der Fertigstellung"}
//        onChange={!readOnly && ((date, dateString) => {handleDatePickerChange("locationFrom", date, dateString)})}
        format={"DD.MM.YYYY"}
//        onBlur={!readOnly && handleSave}
        open={false}
        allowClear={false}
        readOnly={true}
        disabled={!update}
        suffixIcon={null}
      />
    </Col>
    <Col span={5}>
      <Input
        value={raveRelocationEntry.description}
        name={"description"}
        onChange={handleChildChange}
        onBlur={handleChildBlur}
        readOnly={readOnly}
        disabled={!update}
//        style={{backgroundColor: "transparent"}}
      />
    </Col>
    <Col span={3}>
      <Input
        value={raveRelocationEntry.stock}
        name={"stock"}
        onChange={handleChildChange}
        onBlur={handleChildBlur}
        readOnly={readOnly}
        disabled={!update}
//        style={{backgroundColor: "transparent"}}
      />
    </Col>
    <Col span={3}>
      <SelectProjectRooms
        value={raveRelocationEntry.locationId}
        name={"locationId"}
        projectId={projectId}
        showSearch
        allowClear={true}
        placeholder={"Bitte auswählen"}
        fieldNames={{label: "name", value: "id",}}
//        dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
        onChange={(tsData, tsLabel, tsExtra) => {
          handleSelectChange("locationId", tsData)
        }}
        onBlur={handleChildBlur}
        readOnly={readOnly}
        disabled={!update}
        suffixIcon={(!readOnly && update)?<DownOutlined />:null}
        // showArrow={!readOnly && update}
 //        style={{backgroundColor: "transparent"}}
      />
{/*
      <Input
        value={raveRelocationEntry.locationId}
        name={"locationId"}
 //        style={{backgroundColor: "transparent"}}
      />
*/}
    </Col>
    <Col span={3}>
      <SelectCatalog
        name="functionId"
        catalogGroup={"223488a8-fdaf-48ac-844c-caf277f851c4"}
        value={raveRelocationEntry.functionId}
        onChange={(value) => {handleSelectChange("functionId", value)}}
        onBlur={handleChildBlur}
        readOnly={readOnly}
        disabled={!update}
        style={{width: "100%"}}
        suffixIcon={(!readOnly && update)?<DownOutlined />:null}
        // showArrow={!readOnly && update}
      />
    </Col>
    <Col span={1}>
      <Button
        icon={<DeleteOutlined />}
        onClick={(raveRelocationDelete)?() => raveRelocationDelete(raveRelocationEntry.id):null}
        danger
        type={"text"}
      />
    </Col>
    <Col span={7} style={{textAlign: "right"}} />
    </div>
  </Row>
}

RaveRelocationEntry.defaultProps = {
//  assignmentId: null,
  readOnly: false,
  editable: false,
  update: true
}
