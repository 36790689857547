import React, {useEffect, useState} from 'react';
import {Row, Col, DatePicker, Form, Empty, Button} from "antd";
import dayjs from 'dayjs';
import {sysRight} from "../common/cmnRights";
import apiRequest from "../general/apiRequest";
import PanelDndContainer, {parentTypes} from "../panel/panelDndContainer";

const apiUrlShowCapture            = "/capture/byParent"
const apiUrlSaveCapture            = "/capture"
const apiUrlCreateCapture          = "/capture"

const defaultCapture = {
  id: null,
  position: -1,
  projectId: "",
  parentId: "",
  captureDate: null,
}
export const Capture = ({editable, panelStyle = "default", parentId, projectId, rightsParent = [],  viewEntities = "large",...props}) => {
  const [capture, setCapture] = useState({...defaultCapture})
  useEffect(() => {
    loadCapture(parentId)
  }, [parentId]);
  const loadCapture = parentId => {
    if (parentId) {
      apiRequest.get(apiUrlShowCapture + "/" + parentId)
        .then((rd) => {
          if (rd && rd.state && rd.data && rd.data.length > 0)
            setCapture(rd.data[0])
        })
        .catch(error => console.log("loadCapture in Capture: " + error))
    }
  }
  const datePickerChange = (name, date, dateString) => {
    setCapture(prevState => {
      return {...prevState, [name]: date}
    })
  }
  const handleSave = () => {
    apiRequest.put(apiUrlSaveCapture, JSON.stringify(capture))
      .then((rd) => {
//        if (rd && rd.state) console.log("Capture Saved")
      })
      .catch(error => console.log("loadCapture in Capture: " + error))
  }
  const createCapture = () => {
    apiRequest.post(apiUrlCreateCapture + "/" + parentId)
      .then((rd) => {
        if (rd && rd.state && rd.data)
          setCapture(rd.data)
      })
      .catch(error => console.log("createCapture in Capture: " + error))
  }
  const update = (rightsParent.indexOf(sysRight.UPDATE) >= 0)
  const captureDate = (capture && capture.captureDate) ? dayjs(new Date(capture.captureDate)): null
  return <div key={"PLCaptureShowMain" + capture.id}>
    {(capture && capture.id !== null) ?
      <>
        <Row gutter={[16, 16]}>
          <Col span={24}>{(editable)
            ?<Form.Item label="Erfassungsdatum:">
              <DatePicker
                name="completionDate"
                placeholder={"Datum der Fertigstellung"}
                value={captureDate}
                onChange={(date, dateString) => {
                  datePickerChange("captureDate", date, dateString)
                }}
                format={"DD.MM.YYYY"}
                onBlur={() => handleSave()}
                readOnly={!editable || !update} disabled={!update}
              />
            </Form.Item>
            :<>
              <h4><b>Veränderungen / Schäden</b></h4>
              <h6><b>Erfassungsdatum: {(captureDate)? captureDate.format('DD.MM.YYYY'):null}</b></h6>
            </>}
          </Col>
        </Row>
        <PanelDndContainer
          key={"PLCaptureEdit"}
          panelStyle={panelStyle}
          editable={editable}
          viewEntities={viewEntities}
          projectId={projectId}
          workId={capture.id}
          parentType={parentTypes.capture}
          panelTypes={["text", "media", "raveDamage"]}
          rightsParent={rightsParent}
        />
      </>
      :<Empty description="Keine Daten">{(rightsParent.indexOf(sysRight.CREATE) >= 0)?<Button onClick={createCapture}>Erstellen</Button>:null}</Empty>}
  </div>
}
