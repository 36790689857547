import {
  AppstoreOutlined, BankOutlined,
  BlockOutlined, BorderOutlined,
} from "@ant-design/icons";

const ProjectIcon     = BankOutlined
const BreakdownIcon   = BlockOutlined
const RoomIcon        = BorderOutlined
const WorkpieceIcon   = AppstoreOutlined

// const apiUrlProjectList                           = "/project/list"
// const apiUrlProjectListShared                     = "/project/listShared"
// const apiUrlBreakDownListDirectParentOnly         = "/breakdown/byDirectParentOnly"

export {ProjectIcon, BreakdownIcon, RoomIcon, WorkpieceIcon,}