import React, {useEffect, useState} from 'react';
import {TreeSelect} from "antd";
import apiRequest from "../../general/apiRequest";

const apiUrlBreakdownParentTree = "/breakdown/allParentTree"

export const TreeSelectBreakdown = ({allowClear,defaultActiveFirstOption = false, onBlur, onChange, projectId, readOnly = false, withParent = false, workId, ...props}) => {
//  const {readOnly = false, workId, projectId, withParent = false, defaultActiveFirstOption = false, onChange, ...rest} = props
  const [breakdowns, setBreakdowns] = useState([])
  useEffect(() => {
    loadBreakdownTree(projectId)
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId])
  const loadBreakdownTree = (projectId) => {
    if (projectId) {
      apiRequest.get(`${apiUrlBreakdownParentTree}/${projectId}?withParent=${withParent}`)
        .then(rd => {
          if (rd && rd.state && rd.data) {
            setBreakdowns(rd.data)
            if (defaultActiveFirstOption && !props.value && rd.data.length>0) handleChange(rd.data[0].id)
          }
        })
        .catch(error => console.log("Error in TreeSelectBreakdown: " + error))
    }
  }
  const handleChange = (value, node, extra) => {
    if (!readOnly && onChange) onChange(value)
  }
  return (
    <TreeSelect
      key={"CT" + workId}
      style={{ width: '100%' }}
      fieldNames={{label: "name", value: "id", children: "children"}}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      {...props}
      onChange={handleChange}
      onBlur={!readOnly && onBlur}
      allowClear={!readOnly && allowClear}
      open={readOnly ? false : undefined}
      treeData={breakdowns}
    />
  )
}
