import React, {useEffect, useState} from 'react'
import {Card, Col, Form, Input, Row, Space} from "antd";
import {useDispatch,} from "react-redux";
import {/*selectNodeMetaById, */updateOneNodeMetaElement} from "./slice/mrpNodeMetaSlice";
import './Attribute.css'
import {PropertyMetaEditList} from "./PropertyMetaEditList";
import {SelectScope} from "./select/SelectScope";
import {SelectDataSchemeType} from "./select/SelectDataSchemaType";
import {SelectModule} from "./select/SelectModule";
import {SwitchSchema} from "./select/SwitchSchema";
import apiRequest from "../general/apiRequest";
import {EdgeMetaEditList} from "./EdgeMetaEditList";

export const NoteMetaEdit = (props) => {
  const [activeTabKey, setActiveTabKey] = useState("nodeMeta")
  const tabList = [
    { key: "nodeMeta", tab: "nodeMeta", },
//    { key: "propertyMetaList", tab: "propertyMetaList", },
  ]
  const contentList = {
    nodeMeta:
      <NoteMetaDetail {...props} nodeMetaId={props.nodeMetaId}>

      </NoteMetaDetail>,
  }
  const onTabChange = (key) => setActiveTabKey(key)
  return (
    <Card
      tabList={tabList}
      activeTabKey={activeTabKey}
      onTabChange={(key) => {onTabChange(key)}}
    >{contentList[activeTabKey]}</Card>
  )
}
const NoteMetaDetail = (props) => {
  const [changed, setChanged] = useState(false)
  const [nodeMeta, setNodeMeta] = useState(null)
  const dispatch = useDispatch()
  useEffect(() => {
    loadNodeMeta()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const loadNodeMeta = () => {
    apiRequest.get("/nodeMeta/" + props.nodeMetaId)
      .then(rd => {
        if (rd && rd.state && rd.data) {
          setNodeMeta(rd.data)
          setChanged(false)
        }
      })
      .catch(error => console.log("Error in NodeMetaEdit at " + error))
  }
  const handleChangeInput = (evt) => {
    setNodeMeta({...nodeMeta, [evt.target.name]:evt.target.value, })
    setChanged(true)
  }
  const handleSelectChange = (name, value) => {
    setNodeMeta({...nodeMeta, [name]: (value!=="0")?value:null, })
    setChanged(true)
  }
  const handleSwitchChange = (name, value) => {
    setNodeMeta({...nodeMeta, [name]: (value!=="0")?value:null, })
    setChanged(true)
  }
  const handleSave = () => {
    if (changed) {
      apiRequest.put("/nodeMeta", JSON.stringify(nodeMeta))
        .then((rd) => {
          if (rd && rd.state && rd.data) {
            setChanged(false)
            dispatch(updateOneNodeMetaElement(rd.data))
          }
        })
        .catch(error => console.log("handleSave in NoteMetaDetail: " + error))
    }
  }
  return (
    <Row gutter={[16, 24]}>
      <Col span={6}>
        <Card
          title="Node-Values"
          type={"inner"}
        >
          <Form name="node-meta-detail-form"
            className={"NoteMetaDetail"}
            labelCol={{span: 8,}}
            wrapperCol={{span: 24,}}
            layout={"vertical"}
          >
            {nodeMeta?
              <>
            <Form.Item
              label="Name"
            >
              <Input
                name="name"
                value={nodeMeta.name}
                onChange={(evt) => {handleChangeInput(evt)}}
                onBlur={handleSave}
              />
            </Form.Item>
            <Form.Item
              label="Phys.-Name"
            >
              <Input
                name="physicalName"
                value={nodeMeta.physicalName}
                onChange={(evt) => {handleChangeInput(evt)}}
                onBlur={handleSave}
              />
            </Form.Item>
            <Form.Item label="Beschreibung">
              <Input.TextArea
                name="description"
                value={nodeMeta.description}
                autoSize={{minRows: 3, maxRows: 7}}
                maxLength={2000}
//                bordered={false}
                cols={4}
              />
            </Form.Item>
            <Form.Item label="Modul">
              <SelectModule
                name="moduleId"
                value={nodeMeta.moduleId}
                showempty={true}
                disableempty={false}
//                textempty="--- keine Auswahl ---"
                textempty="- leer -"
//                defaultValue="0"
                onChange={(value) => {handleSelectChange("moduleId", value)}}
                onBlur={handleSave}
              />
            </Form.Item>
            <Form.Item label="Verwendung">
              <SelectScope
                name="scopeId"
                value={nodeMeta.scopeId}
//                showempty={true}
//                disableempty={false}
//                textempty="- leer -"
                onChange={(value) => {handleSelectChange("scopeId", value)}}
                onBlur={handleSave}
              />
            </Form.Item>
            <Form.Item label="Datenschema">
              <SelectDataSchemeType
                name="dataSchemaTypeId"
                value={nodeMeta.dataSchemaTypeId}
                showempty={true}
                disableempty={false}
                textempty="- leer -"
                onChange={(value) => {handleSelectChange("dataSchemaTypeId", value)}}
                onBlur={handleSave}
              />
            </Form.Item>
            <Form.Item
//              label="hasSchema"
            >
              <SwitchSchema
                name="hasSchema"
                dataSchemaId={nodeMeta.dataSchemaTypeId}
                checked={nodeMeta.hasSchema}
                onChange={(value, evt) => handleSwitchChange("hasSchema", value)}
              /><label style={{paddingLeft: "1em"}}>hasSchema</label>
            </Form.Item>
              </>
            :null}
          </Form>
        </Card>
      </Col>
      <Col span={18}>
        {nodeMeta?
          <Space direction={"vertical"} size={48} style={{width: "100%"}}>
            <PropertyMetaEditList nodeMetaId={nodeMeta.id} />
            <EdgeMetaEditList nodeMetaId={nodeMeta.id} bindTo="source" />
            <EdgeMetaEditList nodeMetaId={nodeMeta.id} bindTo="target" />
          </Space>
          :null}
      </Col>
    </Row>
  )
}