import React, {useEffect, useState} from 'react';
import {Select} from "antd";
import apiRequest from "../general/apiRequest";

const apiUrlAlternationTypeList         = "raveAlternationType/list"

export const SelectAlternationType = ({allowClear = false, onBlur, onChange, readOnly = false, ...props}) => {
  const [alternationType, setAlternationType] = useState([])
  const loadAlternationType = async () => {
    const rd = await apiRequest.get(apiUrlAlternationTypeList)
    if (rd && rd.state && rd.data) setAlternationType(rd.data)
  }
  useEffect(() => {loadAlternationType()}, [])
  return (
    <Select
      className={readOnly? 'readOnly': undefined}
      key={"coreAlternationType"}
      style={{width: "100%"}}
      fieldNames={{label: "name", value: "id"}}
      dropdownAlign={{ points: ["tr", "br"] }}
      {...props}
      onChange={!readOnly && onChange}
      onBlur={!readOnly && onBlur}
      allowClear={!readOnly && allowClear}
      open={readOnly ? false : undefined}
      options={alternationType}
    />
  )
}
