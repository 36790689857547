import React, {useEffect, useState} from 'react';
import {Select} from "antd";
import apiRequest from "../general/apiRequest";

const apiUrlTextFunctionList         = "baseTextFunction/list"

export const SelectTextFunction = ({allowClear = false, onBlur, onChange, readOnly = false, ...props}) => {
  const [textFunction, setTextFunction] = useState([])
  const loadTextFunction = async () => {
    const rd = await apiRequest.get(apiUrlTextFunctionList)
    if (rd && rd.state && rd.data) setTextFunction(rd.data)
  }
  useEffect(() => {loadTextFunction()}, [])
  return (
    <Select
      className={readOnly? 'readOnly': undefined}
      key={"coreTextFunction"}
//      style={{width: "100%"}}
      fieldNames={{label: "name", value: "id"}}
      dropdownAlign={{ points: ["tr", "br"] }}
      {...props}
      onChange={!readOnly && onChange}
      onBlur={!readOnly && onBlur}
      allowClear={!readOnly && allowClear}
      open={readOnly ? false : undefined}
      options={textFunction}
    />
  )
}
