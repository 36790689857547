import React, {useEffect, useState} from "react";
import apiRequest from "../../../general/apiRequest";
import {Link} from "react-router-dom";

const defLocation = {
  id            : "",
  codeNumber   : "",
  name          : "",
  itemType      : "",
}
const apiUrlGetActiveLocation     = "/raveRelocationEntry/activeLocation"


export const WorkpieceLocation = ({workpieceId, ...props}) => {
  const [location, setLocation] = useState({...defLocation})
//  const {workpieceId} = props
  useEffect(() => {
    loadActiveLocation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.workpieceId])
  const loadActiveLocation = () => {
    if (workpieceId)
      apiRequest.get(`${apiUrlGetActiveLocation}/${workpieceId}`)
        .then(rd => {
          if (rd && rd.state && rd.data) setLocation(rd.data)
        })
        .catch(error => console.log("Error in WorkpieceLocation: " + error))
  }
  return <div className={"relocated"}>
    {(location.id)
      ?(location.itemType === 'project' || location.itemType === 'breakdown' || location.itemType === 'room')
        ?(location.itemType === 'project')
          ?<Link to={`/ras/${location.itemType}/${location.id}`}>{location.name1}<br />{location.name2}</Link>
          :<Link to={`/ras/${location.itemType}/${location.id}`}>{location.name1}<br />{location.name2}</Link>
        :<>{location.name1}<br />{location.name2}</>
      :<>Lager:<br />{location.name1}</>
    }
  </div>
}
