import React, {useState, useCallback, useMemo, useRef, useEffect} from 'react';
import {Row, Col, Checkbox} from "antd";
import {MapContainer, TileLayer, Marker, useMapEvents/*, useMap, Popup*/} from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import {Icon} from "leaflet";
import markerIconPng from "leaflet/dist/images/marker-icon.png"

export const MapChoose = ({/*onOff = false,*/ ...props}) => {
  const [mapOnOff, setMapOnOff] = useState(false)
  useEffect(() => {
    setMapOnOff(true)
  }, []);
  // const center = {lat: 48.54099175, lng: 10.850526793257309, }
  //const center = {lat: props.geocodeData.latitude, lng: props.geocodeData.longitude, }
  const center = [props.geocodeData.latitude, props.geocodeData.longitude]
  function DraggableMarker() {
    const [draggable, setDraggable] = useState(true)
//    const [position, setPosition] = useState(center)
    const [position, setPosition] = useState([props.geocodeData.latitude, props.geocodeData.longitude])
    const markerRef = useRef(null)
    const eventHandlers = useMemo(() => ({
        dragend() {
          const marker = markerRef.current
          if (marker != null) {
            setPosition(marker.getLatLng())
//            console.log("Center: " + marker.getLatLng())
//            fProps.changeLatLon(marker.getLatLng())
            props.changeLatLon(marker.getLatLng())
          }
        },
      }),
      [],
    )
/*
    const toggleDraggable = useCallback(() => {
      setDraggable((d) => !d)
    }, [])
*/

//    console.log("Marker =>: " + [position.lat, position.lng])
//    console.log("Props =>: " + JSON.stringify(myProps))
//    props.changeLatLon({latitude: position.lat, longitude: position.lng})
//    props.changeLatLon({latitude: position.lat, longitude: position.lng})

    return <Marker
//      style={(props.editable)?{cursor: "default"}:{}}
      draggable={(props.editable)?draggable:null}
      icon={new Icon({iconUrl: markerIconPng, iconSize: [18, 30], iconAnchor: [18, 30]})}
      eventHandlers={(props.editable)?eventHandlers:null}
      position={position}
      ref={markerRef}>
    </Marker>
  }
  function MyDrag() {
    /*const map =*/ useMapEvents({
//       dragend: (e) => {console.log("Center: " + e.target.getCenter())},
      zoomend:(e) => {
        props.changeZoom(e.target.getZoom())
      },
    })
    return null
  }
  return <div style={{width: "100%", height: "600px", position: "relative"}}>
    <Row gutter={[16,16]} style={{height: "100%"}}>
      <Col span={18} style={{height: "100%"}}>
        {mapOnOff
        ?<MapContainer
          style={{width: "100%", height: "100%"}}
          id="ModalMap"
          center={center}
          zoomControl={false}
          zoom={props.geocodeData.zoom}
          scrollWheelZoom={props.editable}
          dragging={props.editable}
        >
          <TileLayer attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>'/*contributors*/ url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <DraggableMarker />
          <MyDrag />
          {props.editable?<></>:null}
        </MapContainer>
        :null}
      </Col>
      <Col span={6}>

        <span style={{width: "100%", display: "inline-block", textAlign: "right", marginTop: "2em"}}><b>An/Aus:</b>
          <Checkbox value={mapOnOff} onChange={(e) => setMapOnOff(e.target.checked)}></Checkbox>
        </span>

        <span style={{width: "100%", display: "inline-block", textAlign: "right", marginTop: "2em"}}><b>Longtitude:</b> {props.geocodeData.longitude}</span>
        <span style={{width: "100%", display: "inline-block", textAlign: "right", marginTop: "0em"}}><b>Latitude:</b> {props.geocodeData.latitude}</span>
        <span style={{width: "100%", display: "inline-block", textAlign: "right", marginTop: "0em"}}><b>Zoom:</b> {props.geocodeData.zoom}</span>
      </Col>
    </Row>
  </div>
}