import React, {useEffect, useState} from 'react';
import {Select} from "antd";
import apiRequest from "../general/apiRequest";

const apiUrlRaveAssignmentFunctionList         = "raveAssignmentFunction/list"

export const SelectRaveAssignmentFunction = ({allowClear = false, onBlur, onChange, nodeId, readOnly = false, ...props}) => {
  const [textAssignmentFunction, setAssignmentFunction] = useState([])
  const loadAssignmentFunction = async () => {
    if (nodeId !== "") {
      const rd = await apiRequest.get(`${apiUrlRaveAssignmentFunctionList}/${nodeId}`)
      if (rd && rd.state && rd.data) setAssignmentFunction(rd.data)
    }  }
  useEffect(() => {
    loadAssignmentFunction()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Select
      className={readOnly? 'readOnly': undefined}
      key={"coreTextFunction"}
      style={{width: "100%"}}
      fieldNames={{label: "name", value: "id"}}
      dropdownAlign={{ points: ["tr", "br"] }}
      {...props}
      onChange={!readOnly && onChange}
      onBlur={!readOnly && onBlur}
      allowClear={!readOnly && allowClear}
      open={readOnly ? false : undefined}
      options={textAssignmentFunction}
    />
  )
}
